import cc from "classcat"
import { useFeature } from "flagged"
import { startCase } from "lodash-es"
import React, { useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { P, match } from "ts-pattern"

import styles from "./TopBanner.module.css"

import {
  TopBannerQuery,
  TopBannerQuery$data,
} from "./__generated__/TopBannerQuery.graphql"

import { HasuraContextProvider } from "~/store/hasura"

import { UserType } from "~/helpers/permissions"
import {
  adminAccounts,
  superAdminLogout,
  superAdminSwitchAccount,
  toggleTestAccount,
} from "~/helpers/routes"

import Dialog from "~/common/Dialog"

import EntitlementsProvider from "~/Entitlements/EntitlementsProvider"
import {
  useIsInFreePlan,
  useIsInStarterPlan,
  useIsInTrial,
} from "~/Entitlements/useEntitlements"
import PermissionsProvider from "~/Permissions/PermissionsProvider"
import { usePermissions } from "~/Permissions/usePermissions"
import { withRelayPageContainerNoLoading } from "~/RelayPageContainer"
import ServerFlagsProvider from "~/ServerFlags/ServerFlagsProvider"
import UpgradePlanModal from "~/forms/CreatePerson/UpgradePlanModal"

import CopyFromLiveModal from "../Modal/CopyFromLiveModal"
import ResetDemoDataModal from "../Modal/ResetTestAccountModal"

import LastCopied from "./LastCopied"

const PLAN_URL = "/account/billing"

const TOP_BANNER_QUERY = graphql`
  query TopBannerQuery($accountId: Int!) {
    account: accounts_by_pk(id: $accountId) {
      id
      name
      account_type
      last_copied
      alternative_account {
        id
        projects {
          id
        }
        account_type
      }
    }
    current_user {
      id
      permissions
      ...PermissionsProvider_user
    }

    billing(syncSubscription: false) {
      subscription {
        id
        isInTrial
        trialDaysLeft
      }
      ...EntitlementsProvider_billing
    }
    ...ServerFlagsProvider_gql
  }
`

const BannerContainer = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div id="topBanner" className={cc([styles.container, className])}>
      {children}
    </div>
  )
}

const ViewPlansLink = ({
  userType,
  children,
}: {
  userType: UserType
  children: React.ReactNode
}) => {
  // We only show the view plans link for Admins and when the page is not the
  // billing page
  if (userType !== UserType.Admin || window.location.pathname === PLAN_URL) {
    return null
  }

  return <>{children}</>
}

const StartTrialLink = () => {
  const { can, subject } = usePermissions()
  const [isOpen, setIsOpen] = useState(false)
  const isAdmin = can("manage", subject("Account"))

  return (
    <>
      <a
        onClick={(e) => {
          e.preventDefault()
          setIsOpen(true)
        }}
        data-test="banner-start-trial-link"
      >
        Start a 14 day free trial
      </a>
      <Dialog isOpen={isOpen}>
        <UpgradePlanModal
          isAdmin={isAdmin}
          closeDialog={() => setIsOpen(false)}
          intent="StartTrial"
        />
      </Dialog>
    </>
  )
}
/**
 * XXX: TopBanner is rendered from Rails.
 * Other components have layout dependencies on this component indirectly via planner-helpers/willBannerRender
 **/
const TopBanner = ({ data }: { data: TopBannerQuery$data }) => {
  const { account, current_user, billing } = data
  const entitlementsEnabled = useFeature("subscription_entitlements")
  const { Can, subject } = usePermissions()

  const isInTrial = useIsInTrial()
  const isInFreePlan = useIsInFreePlan()
  const isOnStarterPlan = useIsInStarterPlan()
  const trialDaysLeft = billing.subscription
    ? billing.subscription.trialDaysLeft !== null
      ? billing.subscription.trialDaysLeft
      : null
    : null

  const [copyDialogOpen, setCopyDialogOpen] = useState(false)
  const [resetDialogOpen, setResetDialogOpen] = useState(false)
  const [isBlurred, setIsBlurred] = useState(false)
  const iscopyFromLiveDisabled = !!useFeature("disable_copy_live_account")

  const handleClickBlur = () => {
    setIsBlurred(!isBlurred)
    document.body.classList.toggle("blur-on")
  }

  const handleOpenCopyDialog = () => {
    setCopyDialogOpen(true)
  }
  const handleCloseCopyDialog = () => {
    setCopyDialogOpen(false)
  }

  const handleOpenResetDialog = () => {
    setResetDialogOpen(true)
  }
  const handleCloseResetDialog = () => {
    setResetDialogOpen(false)
  }

  const trialText = entitlementsEnabled
    ? isOnStarterPlan
      ? "starter trial"
      : "professional trial"
    : "trial"

  const bannerContents = match({
    permissions: current_user.permissions.type as UserType,
    accountType: account.account_type,
    entitlementsEnabled,
    isInTrial,
    isInFreePlan,
    trialDaysLeft,
  })
    .with({ permissions: UserType.Superuser, accountType: P._ }, () => (
      <BannerContainer className={styles.superUserBanner}>
        Logged in as&nbsp;<b>SUPERUSER</b>&nbsp;in&nbsp;
        <b>{account.name}</b>'s account.&nbsp;
        <span className={styles.list}>
          <a href={adminAccounts()}>Switch Accounts</a>
          {" | "}
          <a href={superAdminSwitchAccount()}>Home</a>
          {" | "}
          <a href={superAdminLogout()}>Home & Logout</a>
        </span>
        <span className={styles.demoActions}>
          <a onClick={handleClickBlur}>{isBlurred ? "Unblur" : "Blur"}</a>
        </span>
      </BannerContainer>
    ))
    .with({ permissions: P._, accountType: "test" }, () => (
      <BannerContainer className={styles.testBanner}>
        {account.alternative_account.projects?.length === 0 ? (
          <span>
            <b> Test Account</b>. Finished exploring?{" "}
            <a href={toggleTestAccount()}>Set up your account</a>
          </span>
        ) : (
          <span>
            <b>Shared Test Account</b>. Switch back to{" "}
            <a href={toggleTestAccount()}>
              {startCase(account.alternative_account.account_type)} Account
            </a>
          </span>
        )}
        <span className={styles.demoActions}>
          {account.last_copied && (
            <LastCopied date={new Date(account.last_copied)} />
          )}
          {!iscopyFromLiveDisabled && (
            <>
              <Can I="copy_data_from_live" this={subject("Account")}>
                <a onClick={handleOpenCopyDialog}>Copy Live Account Data</a>
                {" | "}
              </Can>
              <Can I="seed_account_with_demo_data" this={subject("Account")}>
                <a onClick={handleOpenResetDialog}>Reset Test Account</a>
              </Can>
            </>
          )}
        </span>
        <Dialog isOpen={copyDialogOpen} enforceFocus>
          <CopyFromLiveModal onClose={handleCloseCopyDialog} />
        </Dialog>
        <Dialog isOpen={resetDialogOpen} enforceFocus>
          <ResetDemoDataModal onClose={handleCloseResetDialog} />
        </Dialog>
      </BannerContainer>
    ))
    .with({ permissions: P._, accountType: "copy" }, () => (
      <BannerContainer>
        <b>Copy Account</b>. Switch back to&nbsp;
        <a href={toggleTestAccount()}>
          {startCase(account.alternative_account.account_type)} Account
        </a>
        <span className={styles.demoActions}>
          {account.last_copied && (
            <LastCopied date={new Date(account.last_copied)} />
          )}
          {!iscopyFromLiveDisabled && (
            <Can I="copy_data_from_live" this={subject("Account")}>
              <a onClick={handleOpenCopyDialog}>Copy Live Account Data</a>
            </Can>
          )}
        </span>
        <Dialog isOpen={copyDialogOpen} enforceFocus>
          <CopyFromLiveModal onClose={handleCloseCopyDialog} />
        </Dialog>
      </BannerContainer>
    ))
    .with({ permissions: P._, accountType: "demo_template" }, () => (
      <BannerContainer>
        <b>Demo Template (ID: {account.id})</b>. Switch back to&nbsp;
        <a href={toggleTestAccount()}>
          {startCase(account.alternative_account.account_type)} Account (ID:{" "}
          {account.alternative_account.id})
        </a>
        <span className={styles.demoActions}>
          <a onClick={handleOpenCopyDialog}>Copy template to demo</a>
        </span>
        <Dialog isOpen={copyDialogOpen} enforceFocus>
          <CopyFromLiveModal onClose={handleCloseCopyDialog} />
        </Dialog>
      </BannerContainer>
    ))
    .with({ entitlementsEnabled: false, isInTrial: true }, () => {
      /**
       * Trial banner for non-entitlement plans
       */
      return (
        <BannerContainer>
          {trialDaysLeft === 1 ? (
            "Trial ends today"
          ) : (
            <span>
              <strong>{trialDaysLeft}</strong> days left in trial
            </span>
          )}
        </BannerContainer>
      )
    })
    .with(
      { entitlementsEnabled: true, isInTrial: true, trialDaysLeft: 1 },
      () => (
        /**
         * Trial banner for entitlement plans where there is a single day remaining
         */
        <BannerContainer>
          <span>
            You have <strong>1</strong> day remaining on your {trialText}
            <ViewPlansLink userType={current_user.permissions.type}>
              .{" "}
              <strong>
                <a href={PLAN_URL}>Select a plan</a>
              </strong>{" "}
              to continue using your favourite features
            </ViewPlansLink>
          </span>
        </BannerContainer>
      ),
    )
    .with(
      {
        entitlementsEnabled: true,
        isInTrial: true,
        trialDaysLeft: P.number.gt(0),
      },
      () => (
        /**
         * Trial banner for entitlement plans where there are multiple days
         * remaining
         */
        <BannerContainer>
          <span>
            You have <strong>{trialDaysLeft}</strong> days remaining on your{" "}
            {trialText}
            <ViewPlansLink userType={current_user.permissions.type}>
              <>
                .{" "}
                <strong>
                  <a href={PLAN_URL}>View plans</a>
                </strong>
              </>
            </ViewPlansLink>
          </span>
        </BannerContainer>
      ),
    )
    .with(
      { entitlementsEnabled: true, isInTrial: true, trialDaysLeft: 0 },
      () => (
        /**
         * Trial banner for entitlement plans where the trial has finished
         */
        <BannerContainer>
          <span>
            Your trial has ended
            <ViewPlansLink userType={current_user.permissions.type}>
              <>
                .{" "}
                <strong>
                  <a href={PLAN_URL}>Select a plan</a>
                </strong>{" "}
                to continue using your favourite features
              </>
            </ViewPlansLink>
          </span>
        </BannerContainer>
      ),
    )
    .with({ isInFreePlan: true }, () => (
      /**
       * Get people to start a trial
       */
      <BannerContainer>
        <span>
          <StartTrialLink /> to manage unlimited people
        </span>
      </BannerContainer>
    ))
    .otherwise(() => null)

  return (
    <HasuraContextProvider value={current_user}>
      {bannerContents}
    </HasuraContextProvider>
  )
}

const TopBannerContainer = (props: { accountId: number }) => {
  const { accountId } = props
  const data = useLazyLoadQuery<TopBannerQuery>(TOP_BANNER_QUERY, {
    accountId,
  })

  return (
    <PermissionsProvider user={data.current_user}>
      <EntitlementsProvider billing={data.billing}>
        <ServerFlagsProvider gql={data}>
          <TopBanner data={data} />
        </ServerFlagsProvider>
      </EntitlementsProvider>
    </PermissionsProvider>
  )
}

export default withRelayPageContainerNoLoading(TopBannerContainer)
