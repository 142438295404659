import { AnyAbility } from "@casl/ability"
import { BoundCanProps, Can as OrigCan } from "@casl/react"
import {
  AbilityMap,
  AbilityMapWithAccountContext,
  AppAbility,
  AuthType,
  AvailableActions,
  SubjectForContext,
  User,
  definePermissionsFor,
  subject,
} from "@runn/permissions"
import memoize from "lodash-es/memoize"
import { Consumer, FunctionComponent, createElement as h } from "react"
import React, { createContext } from "react"
import { graphql, useFragment } from "react-relay"

import { PermissionsProvider_user$key } from "./__generated__/PermissionsProvider_user.graphql"

import { UserType } from "~/helpers/permissions"
import { Permissions } from "~/helpers/permissions"

type Props = {
  user: PermissionsProvider_user$key
  children: React.ReactNode
}

export type PermissionsContextType = {
  /** @deprecated: permission are checked via can instead of ability **/
  ability: AppAbility
  can: (
    action: AvailableActions,
    subject: ReturnType<SubjectForContext>,
  ) => boolean
  somePermissionExistsFor: AppAbility["can"]
  /** Subject with account context injected */
  subject: SubjectForContext
  /** @deprecated: permission are checked via can instead of user type **/
  isAdmin: boolean
  isAdminWithManageAccount: boolean
  /** @deprecated: permission are checked via can instead of user type **/
  isManager: boolean
}

const FRAGMENT = graphql`
  fragment PermissionsProvider_user on users {
    id
    email
    account {
      id
      timesheets_protected
    }
    permissions
    manageable_projects {
      id
      project {
        id
      }
    }
    manageable_people {
      id
      person {
        id
      }
    }
    linkedPerson {
      id
    }
  }
`

const customCreateContextualCan = <T extends AnyAbility, SubjectForContext>(
  Getter: Consumer<{ ability: T; subject: SubjectForContext } | null>,
): FunctionComponent<BoundCanProps<T>> => {
  return (props: BoundCanProps<T>) =>
    Getter &&
    h(Getter, {
      children: ({ ability }: { ability: T; subject: SubjectForContext }) =>
        h(OrigCan, {
          ability,
          ...props,
        } as any),
    })
}

export const PermissionsContext = createContext<PermissionsContextType | null>(
  null,
)
export const Can = customCreateContextualCan<AppAbility, SubjectForContext>(
  PermissionsContext.Consumer,
)

type UsePermissionsContextProps = {
  user: PermissionsProvider_user$key
}

// Cache since definition is an expensive operation.
// User permission attributes can change due to app interactions,
// for example through the addition of new "manageable_projects"
const memoizedDefinePermissionsFor = memoize(definePermissionsFor)

// Only use this directly in toplevel components *above* <PermissionsProvider>.
// Prefer usePermissions() in components *below* <PermissionsProvider>.
const usePermissionsContext = (props: UsePermissionsContextProps) => {
  const user = useFragment(FRAGMENT, props.user)
  const permissions = user.permissions as Permissions

  const userContext: User = {
    authType: AuthType.User,
    id: user.id,
    email: user.email,
    account: {
      id: user.account.id,
      timesheets_protected: user.account.timesheets_protected ?? false,
    },
    permissions: user.permissions,
    manageable_projects: user.manageable_projects,
    manageable_people: user.manageable_people,
    person: user.linkedPerson,
  }

  const ability = memoizedDefinePermissionsFor(userContext)

  // Enforces the use of `ability.can` with a subject helper
  const can = (...args) =>
    ability.can(...(args as Parameters<AppAbility["can"]>))

  // Only allow instanceless permission checks
  const somePermissionExistsFor = (...args) => {
    if (args.some((arg) => typeof arg !== "string")) {
      throw new Error("All arguments must be strings")
    }
    return ability.can(...(args as Parameters<AppAbility["can"]>))
  }

  // Wrap permission subjects and inject account id to original object
  const subjectWithAccount: SubjectForContext = <
    T extends keyof AbilityMapWithAccountContext,
  >(
    name: T,
    obj?: AbilityMapWithAccountContext[T],
  ) => {
    const entity = {
      ...obj,
      account: { id: user.account.id },
    } as AbilityMap[T]

    return subject(name, entity)
  }

  return {
    ability,
    can,
    somePermissionExistsFor,
    subject: subjectWithAccount,
    isAdmin: [UserType.Superuser, UserType.Admin].includes(permissions.type),
    isAdminWithManageAccount:
      (permissions.type === UserType.Admin && permissions.manage_account) ||
      permissions.type === UserType.Superuser,
    isManager: permissions.type === UserType.Manager,
    isContributor: permissions.type === UserType.Contributor,
  }
}

const PermissionsProvider = (props: Props) => {
  const { user, children } = props

  return (
    <PermissionsContext.Provider value={usePermissionsContext({ user })}>
      {children}
    </PermissionsContext.Provider>
  )
}

export default PermissionsProvider
export { usePermissionsContext }
