import { Icon } from "@blueprintjs/core"
import React from "react"

import styles from "./ImportUsersInNovuAction.module.css"

import { sessionStore } from "~/store/hasura"

import { ModalBody, ModalFormWrapper } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"

import { withRelayPageContainer } from "~/RelayPageContainer"

const ImportUsersInNovuAction = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [logs, setLogs] = React.useState<string[]>([])

  const onClick = async () => {
    setLogs([])

    const hasuraAuthToken = await sessionStore.getAuthToken()
    const nodeServerEndpoint = await sessionStore.getNodeServerEndpoint()

    const response = await fetch(`${nodeServerEndpoint}/import-users-in-novu`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${hasuraAuthToken}`,
      },
    })

    if (response.ok) {
      const json = await response.json()

      setLogs(json.logs)
    } else {
      const text = await response.text()

      setLogs([text])
    }

    setIsModalOpen(true)
  }

  return (
    <div>
      <Button
        text="Import Users in Novu"
        onClick={onClick}
        icon={<Icon icon="add" />}
        style={{ padding: "0 6px" }}
      />
      {isModalOpen && (
        <div className={styles.modalContainer}>
          <ModalFormWrapper
            headerTitle="Import Users in Novu Logs"
            tangramStyles={{ maxWidth: "80%", marginBottom: "10px" }}
            onClose={() => setIsModalOpen(false)}
          >
            <ModalBody className={styles.body}>
              {logs.map((log, index) => (
                <span key={index} className={styles.log}>
                  {log}
                </span>
              ))}
            </ModalBody>
          </ModalFormWrapper>
        </div>
      )}
    </div>
  )
}

export default withRelayPageContainer(ImportUsersInNovuAction)
