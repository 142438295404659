import { Dialog } from "@blueprintjs/core"
import React from "react"

import styles from "./NotificationsDialog.module.css"

import { sessionStore } from "~/store/hasura"

import { accountSettings, bulkEditPeopleUrl } from "~/helpers/routes"

import { ModalBody, ModalFormWrapper } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"

type Props = {
  isOpen: boolean
  onClose: () => void
}

const AssetsDialog = (props: Props) => {
  const { isOpen, onClose } = props

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [logs, setLogs] = React.useState<string[]>([])

  const onImportUsersInNovu = async () => {
    setLogs([])

    const hasuraAuthToken = await sessionStore.getAuthToken()
    const nodeServerEndpoint = await sessionStore.getNodeServerEndpoint()

    const response = await fetch(`${nodeServerEndpoint}/import-users-in-novu`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${hasuraAuthToken}`,
      },
    })

    if (response.ok) {
      const json = await response.json()

      setLogs(json.logs)
    } else {
      const text = await response.text()

      setLogs([text])
    }

    setIsModalOpen(true)
  }

  const onTimesheetNotCompleted = async () => {
    const hasuraAuthToken = await sessionStore.getAuthToken()
    const nodeServerEndpoint = await sessionStore.getNodeServerEndpoint()

    const response = await fetch(
      `${nodeServerEndpoint}/trigger-timesheet-not-completed-notification`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${hasuraAuthToken}`,
        },
      },
    )

    if (response.ok) {
      alert(
        "Success: If you have not completed timesheet last week, you should receive a notification shortly.",
      )
    } else {
      const text = await response.text()

      alert(`Error: ${text}`)
    }
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <h1>
          <span className={styles.bell}>🔔</span>
          Notifications
        </h1>
        <div className={styles.actions}>
          <div>
            <p>Import all the users of the current Account in Novu.</p>
            <Button onClick={onImportUsersInNovu}>Import users in Novu</Button>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
            <p>
              Manually trigger the timesheet not completed notification for the
              current Account.
            </p>
            <span className={styles.step}>
              1. Turn on the `bypass_chargebee` feature flag
            </span>
            <span className={styles.step}>
              2. Enable Timesheets and Timesheet notifications in the{" "}
              <a target="_blank" rel="noreferrer" href={accountSettings()}>
                account settings
              </a>
            </span>
            <span className={styles.step}>
              3. Add the email you used to log in to a person in{" "}
              <a target="_blank" rel="noreferrer" href={bulkEditPeopleUrl()}>
                bulk edit people
              </a>
            </span>
            <span className={styles.step}>
              4. Add assignments for last week to the same person
            </span>
            <span className={styles.step}>
              5. Import all users in Novu{" "}
              <Button onClick={onImportUsersInNovu}>Import users</Button>
            </span>
            <span className={styles.step}>
              6. Trigger the notification{" "}
              <Button onClick={onTimesheetNotCompleted}>
                Timesheet not completed
              </Button>
            </span>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className={styles.modalContainer}>
          <ModalFormWrapper
            headerTitle="Import Users in Novu Logs"
            tangramStyles={{ maxWidth: "80%", marginBottom: "10px" }}
            onClose={() => setIsModalOpen(false)}
          >
            <ModalBody className={styles.modalBody}>
              {logs.map((log, index) => (
                <span key={index} className={styles.log}>
                  {log}
                </span>
              ))}
            </ModalBody>
          </ModalFormWrapper>
        </div>
      )}
    </Dialog>
  )
}

export default AssetsDialog
