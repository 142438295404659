import { Icon } from "@blueprintjs/core"
import React, { useState } from "react"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"

import styles from "./AccountActions.module.css"

import { AccountActionsContainerQuery } from "./__generated__/AccountActionsContainerQuery.graphql"
import { AccountActions_account$key } from "./__generated__/AccountActions_account.graphql"

import { HasuraContextProvider } from "~/store/hasura"

import Dialog from "~/common/Dialog"
import PermissionsTooltip from "~/common/PermissionsTooltip"
import Button from "~/common/buttons/Button"

import PermissionsProvider from "~/Permissions/PermissionsProvider"
import { usePermissions } from "~/Permissions/usePermissions"
import { withRelayPageContainer } from "~/RelayPageContainer"
import { Card, CardHeader } from "~/dashboard"
import AutofillTimesheetsForm from "~/forms/AccountForms/AutofillTimesheetsForm"
import DeleteAccountForm from "~/forms/AccountForms/DeleteAccountForm"
import ResetAccountForm from "~/forms/AccountForms/ResetAccountForm"

type ContainerProps = {
  error?: Record<string, any>
  accountId: number
}

type Props = {
  account: AccountActions_account$key
}

const AccountActions = (props: Props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showResetModal, setShowResetModal] = useState(false)
  const [showAutofillModal, setShowAutofillModal] = useState(false)

  const account = useFragment(
    graphql`
      fragment AccountActions_account on accounts {
        name
        id
        account_type
        subscription
        users {
          permissions
        }
        cancel_reasons {
          account_name
        }
      }
    `,
    props.account,
  )

  const [showActions, setShowActions] = useState(false)
  const toggleShowActions = () => setShowActions(!showActions)

  const { can, subject } = usePermissions()
  const isLiveAccount = account.account_type === "live"

  if (!isLiveAccount || !can("manage", subject("Account"))) {
    return null
  }

  const canEditAccount = can("edit", subject("Account"))
  const canDeleteAccount = can("delete", subject("Account"))
  const canManageActuals = can("manage", subject("Actual"))

  return (
    <div className="mv4 br2 ba b--mid-gray-2 shadow-18 w-100 w-50-l">
      <Card>
        <CardHeader noSticky>
          <span>Account Actions</span>
          <Button onClick={toggleShowActions}>
            {showActions ? "Hide" : "Show"} Actions
          </Button>
        </CardHeader>
        <>
          <div className={styles.wrapper}>
            <b>
              These actions will permanently change the entire account and all
              data.
            </b>
            <br />
            <span>
              If you wish to just remove yourself as user go to My Settings
              instead.
            </span>
            {showActions && (
              <div>
                <div>
                  <p className={styles.sectionHeading}>Account data</p>
                  <div className={styles.buttonContainer}>
                    <PermissionsTooltip
                      action="reset"
                      subject="the account"
                      disabled={canEditAccount}
                    >
                      <Button
                        text="Reset Account"
                        onClick={() => setShowResetModal(true)}
                        icon={<Icon icon="reset" />}
                        disabled={!canEditAccount}
                      />
                    </PermissionsTooltip>
                    <PermissionsTooltip
                      action="delete"
                      subject="the account and all data"
                      disabled={canDeleteAccount}
                    >
                      <Button
                        text="Delete Account & All Data"
                        onClick={() => setShowDeleteModal(true)}
                        icon={<Icon icon="trash" />}
                        disabled={!canDeleteAccount}
                      />
                    </PermissionsTooltip>
                  </div>
                  <b>These actions can not be reversed.</b>
                </div>
                <div>
                  <p className={styles.sectionHeading}>Timesheet data</p>
                  <div className={styles.buttonContainer}>
                    <PermissionsTooltip
                      action="autofill"
                      subject="timesheets with zero"
                      disabled={canManageActuals}
                    >
                      <Button
                        text="Autofill with zero"
                        onClick={() => setShowAutofillModal(true)}
                        icon={<Icon icon="add" />}
                        disabled={!canManageActuals}
                      />
                    </PermissionsTooltip>
                  </div>
                  <b>This action can not be reversed.</b>
                </div>
              </div>
            )}
          </div>
          <Dialog isOpen={showDeleteModal}>
            <DeleteAccountForm
              account={account}
              onClose={() => setShowDeleteModal(false)}
            />
          </Dialog>
          <Dialog isOpen={showResetModal}>
            <ResetAccountForm
              accountName={account.name}
              onClose={() => setShowResetModal(false)}
            />
          </Dialog>
          <Dialog isOpen={showAutofillModal}>
            <AutofillTimesheetsForm
              onClose={() => setShowAutofillModal(false)}
            />
          </Dialog>
        </>
      </Card>
    </div>
  )
}

const AccountActionsContainer = (props: ContainerProps) => {
  const { accountId } = props
  const data = useLazyLoadQuery<AccountActionsContainerQuery>(
    graphql`
      query AccountActionsContainerQuery($accountId: Int!) {
        current_user {
          id
          ...PermissionsProvider_user
        }
        account: accounts_by_pk(id: $accountId) {
          ...AccountActions_account
        }
      }
    `,
    { accountId },
  )

  const { current_user, account } = data

  return (
    <PermissionsProvider user={current_user}>
      <HasuraContextProvider value={current_user}>
        <AccountActions account={account} />
      </HasuraContextProvider>
    </PermissionsProvider>
  )
}

export default withRelayPageContainer(AccountActionsContainer)
