import React from "react"

import Select, { ReactSelectProps } from "~/common/Select"

import {
  MANAGE_ACCOUNT_PERMISSIONS,
  ManageAccountPermissionsOption,
} from "~/GLOBALVARS"

type Props = ReactSelectProps & {
  onChange: (value: ManageAccountPermissionsOption) => void
  value: ManageAccountPermissionsOption
  isDisabled?: boolean
}

const SelectManageAccountPermission = (props: Props) => {
  const { onChange, value, label, name, id, ...rest } = props

  const selectedFinancialPermission = value
    ? MANAGE_ACCOUNT_PERMISSIONS.find((p) => p.value === value)
    : MANAGE_ACCOUNT_PERMISSIONS[1]

  const handleChange = (e: ManageAccountPermissionsOption) => {
    onChange(e)
  }

  return (
    <Select
      name={name || "user-manage-account-permission"}
      id={id || "user-manage-account-permission"}
      value={selectedFinancialPermission}
      onChange={handleChange}
      label={label || ""}
      options={MANAGE_ACCOUNT_PERMISSIONS}
      placeholder="Select Manage Account Permission"
      doNotSortOptions
      {...rest}
    />
  )
}

export default SelectManageAccountPermission
