/**
 * @generated SignedSource<<de7abe9a9088dc5bfbad66e1e54876e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectTeamUpdateMutation$variables = {
  id: number;
  teamId?: number | null | undefined;
};
export type ProjectTeamUpdateMutation$data = {
  readonly updateProjectTeam: {
    readonly account: {
      readonly id: number;
      readonly teams: ReadonlyArray<{
        readonly id: number;
        readonly " $fragmentSpreads": FragmentRefs<"TeamProjects_team">;
      }>;
    } | null | undefined;
    readonly project: {
      readonly id: number;
      readonly name: string;
    } | null | undefined;
    readonly teamId: number | null | undefined;
  };
};
export type ProjectTeamUpdateMutation = {
  response: ProjectTeamUpdateMutation$data;
  variables: ProjectTeamUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "teamId",
        "variableName": "teamId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "projects",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectTeamUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProjectTeamUpdateOutput",
        "kind": "LinkedField",
        "name": "updateProjectTeam",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "teams",
                "kind": "LinkedField",
                "name": "teams",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TeamProjects_team"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectTeamUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProjectTeamUpdateOutput",
        "kind": "LinkedField",
        "name": "updateProjectTeam",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "teams",
                "kind": "LinkedField",
                "name": "teams",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "order_by",
                        "value": {
                          "name": "asc"
                        }
                      },
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "active": {
                            "_eq": true
                          }
                        }
                      }
                    ],
                    "concreteType": "projects",
                    "kind": "LinkedField",
                    "name": "projects",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "clients",
                        "kind": "LinkedField",
                        "name": "client",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "website",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "image_key",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "projects(order_by:{\"name\":\"asc\"},where:{\"active\":{\"_eq\":true}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e264dc367d0ec4f00232fc24fd987d55",
    "id": null,
    "metadata": {},
    "name": "ProjectTeamUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectTeamUpdateMutation(\n  $id: Int!\n  $teamId: Int\n) {\n  updateProjectTeam(input: {id: $id, teamId: $teamId}) {\n    project {\n      id\n      name\n    }\n    teamId\n    account {\n      id\n      teams {\n        id\n        ...TeamProjects_team\n      }\n    }\n  }\n}\n\nfragment TeamProjects_team on teams {\n  id\n  projects(where: {active: {_eq: true}}, order_by: {name: asc}) {\n    id\n    name\n    client {\n      id\n      website\n      image_key\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0dc16a437d1835ce2b5a89103f69310";

export default node;
