import { Dialog } from "@blueprintjs/core"
import React from "react"
import { useState } from "react"

import PlanButton from "~/common/buttons/PlanButton"

import { usePermissions } from "~/Permissions/usePermissions"
import UpgradePlanModal from "~/forms/CreatePerson/UpgradePlanModal"

import {
  CHARGEBEE_PROFESSIONAL_TRIAL_PRICE_ITEM_ID,
  ChargebeeUIFeatureId,
  getPlansForFeature,
} from "./plansAndFeatures"
import { useIsInFreePlan, useIsInTrial, usePlan } from "./useEntitlements"

type Props = {
  featureId: ChargebeeUIFeatureId
}

const TrialPlanButton = ({ featureId }: Props) => {
  const [modalIntent, setModalIntent] = useState<
    null | "StartProfessionalTrial" | "SwitchToProfessionalTrial"
  >(null)
  const { can, subject } = usePermissions()
  const plan = usePlan()
  const isInFreePlan = useIsInFreePlan()
  const isInTrial = useIsInTrial()

  const isAdmin = can("manage", subject("Account"))

  const plans = getPlansForFeature(featureId)
  // If the feature is available on at least the professional plan, then we can
  // show the "Start Professional Trial" button. If it's not then we don't show
  // the button letting them jump to professional.
  const featureAvailableOnAtLeastProfessional =
    plans.includes("runn-professional")
  const isOnStarterTrial = plan.includes("runn-starter") && isInTrial

  if (!isAdmin) {
    return null
  }

  const dialog = (
    <Dialog isOpen={!!modalIntent}>
      <UpgradePlanModal
        isAdmin={isAdmin}
        closeDialog={() => setModalIntent(null)}
        planId={CHARGEBEE_PROFESSIONAL_TRIAL_PRICE_ITEM_ID}
        intent={modalIntent}
      />
    </Dialog>
  )

  if (isInFreePlan && featureAvailableOnAtLeastProfessional) {
    // Free plans can choose to start the trial on professional.
    return (
      <>
        <PlanButton
          label="Start Professional Trial"
          onClick={(e) => {
            e.preventDefault()
            setModalIntent("StartProfessionalTrial")
          }}
        />
        {dialog}
      </>
    )
  }

  // Those who are in the starter plan and are in trial can upgrade the
  // professional trial.
  if (isOnStarterTrial && featureAvailableOnAtLeastProfessional) {
    return (
      <>
        <PlanButton
          label="Switch to professional trial"
          onClick={(e) => {
            e.preventDefault()
            setModalIntent("SwitchToProfessionalTrial")
          }}
        />
        {dialog}
      </>
    )
  }

  return null
}

export default TrialPlanButton
