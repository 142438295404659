/**
 * @generated SignedSource<<32a0ab8d96772a95e951c0e5fa66a27b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PersonBulkUpdateTeamMutation$variables = {
  peopleIds: ReadonlyArray<number>;
  teamId?: number | null | undefined;
};
export type PersonBulkUpdateTeamMutation$data = {
  readonly bulkUpdatePeopleTeam: {
    readonly account: {
      readonly id: number;
      readonly people: ReadonlyArray<{
        readonly id: number;
        readonly team: {
          readonly id: number;
          readonly name: string;
        } | null | undefined;
        readonly team_id: number | null | undefined;
      }>;
      readonly teams: ReadonlyArray<{
        readonly id: number;
        readonly people: ReadonlyArray<{
          readonly id: number;
          readonly team: {
            readonly id: number;
          } | null | undefined;
          readonly team_id: number | null | undefined;
        }>;
      }>;
    } | null | undefined;
  };
};
export type PersonBulkUpdateTeamMutation = {
  response: PersonBulkUpdateTeamMutation$data;
  variables: PersonBulkUpdateTeamMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "peopleIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "team_id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "peopleIds",
            "variableName": "peopleIds"
          },
          {
            "kind": "Variable",
            "name": "teamId",
            "variableName": "teamId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "BulkUpdatePeopleTeamResult",
    "kind": "LinkedField",
    "name": "bulkUpdatePeopleTeam",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "teams",
            "kind": "LinkedField",
            "name": "teams",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "people",
                "kind": "LinkedField",
                "name": "people",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "teams",
                    "kind": "LinkedField",
                    "name": "team",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "people",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "teams",
                "kind": "LinkedField",
                "name": "team",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonBulkUpdateTeamMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PersonBulkUpdateTeamMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "0d0b43e7cbb5a5777f90d266cdae5542",
    "id": null,
    "metadata": {},
    "name": "PersonBulkUpdateTeamMutation",
    "operationKind": "mutation",
    "text": "mutation PersonBulkUpdateTeamMutation(\n  $peopleIds: [Int!]!\n  $teamId: Int\n) {\n  bulkUpdatePeopleTeam(input: {peopleIds: $peopleIds, teamId: $teamId}) {\n    account {\n      id\n      teams {\n        id\n        people {\n          id\n          team_id\n          team {\n            id\n          }\n        }\n      }\n      people {\n        id\n        team_id\n        team {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "029800107b9f8bbdeb0ebaed408373b4";

export default node;
