import { P, match } from "ts-pattern"

import { EntitlementsContextData } from "./EntitlementsContext"

// Enterprises may have the `access-all-features` entitlement which allows them to
// access all features regardless of the other entitlements.
export const canAccessAllFeatures = (
  entitlements: EntitlementsContextData["entitlements"],
): boolean => {
  return (
    entitlements?.some(
      (entitlement) => entitlement.featureId === "access-all-features",
    ) ?? false
  )
}

export const checkEntitlementSwitch = (
  entitlements: EntitlementsContextData["entitlements"],
  featureId: string,
): boolean => {
  // If `access-all-features` is enabled, we return always return true
  if (canAccessAllFeatures(entitlements)) {
    return true
  }

  const switchEntitlement = entitlements?.find(
    (entitlement) => entitlement.featureId === featureId,
  )

  if (switchEntitlement?.featureType !== "SWITCH") {
    return false
  }

  return switchEntitlement.value === "true"
}

export const checkEntitlementQuantity = (
  entitlements: EntitlementsContextData["entitlements"],
  featureId: string,
) => {
  const quantityEntitlement = (entitlements ?? []).find(
    (entitlement) => entitlement.featureId === featureId,
  )

  // If `access-all-features` is enabled, we return inifite but only if the
  // quantity has not been set. History logs is a good example of why we do this.
  // This is explicitly set in the entitlements so we adhere to that.
  if (canAccessAllFeatures(entitlements) && !quantityEntitlement) {
    return Infinity
  }

  if (!quantityEntitlement) {
    return 0
  }

  return (
    match({
      featureType: quantityEntitlement.featureType,
      value: quantityEntitlement.value.toLowerCase(),
    })
      .with({ featureType: "QUANTITY", value: "unlimited" }, () => Infinity)
      .with(
        { featureType: "QUANTITY", value: P.string },
        () => +quantityEntitlement.value,
      )
      // Note that we are assuming here that `custom` will always be a stringified number
      .with({ featureType: "CUSTOM", value: "unlimited" }, () => Infinity)
      .with(
        { featureType: "CUSTOM", value: P.string },
        () => +quantityEntitlement.value,
      )
      .with({ featureType: "SWITCH", value: "true" }, () => Infinity)
      .with({ featureType: "SWITCH", value: "false" }, () => 0)
      .with({ featureType: "SWITCH", value: P.string }, () => 0)
      .exhaustive()
  )
}

export const checkSwitchOrQuantityAsBoolean = (
  entitlements: EntitlementsContextData["entitlements"],
  featureId: string,
): boolean => {
  const entitlement = (entitlements ?? []).find(
    (e) => e.featureId === featureId,
  )

  if (!entitlement) {
    return false
  }

  if (entitlement.featureType === "SWITCH") {
    return checkEntitlementSwitch(entitlements, featureId)
  }

  if (["QUANTITY", "CUSTOM"].includes(entitlement.featureType)) {
    const quantity = checkEntitlementQuantity(entitlements, featureId)
    return quantity > 0
  }

  return false
}
