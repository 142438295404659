/**
 * @generated SignedSource<<c3a97f7ed3ed93c96fa95fa4aba4b97a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConvertPersonTagToSkillQuery$variables = Record<PropertyKey, never>;
export type ConvertPersonTagToSkillQuery$data = {
  readonly current_user: {
    readonly account: {
      readonly allSkills: ReadonlyArray<{
        readonly id: number;
        readonly name: string;
      }>;
      readonly id: number;
      readonly people: ReadonlyArray<{
        readonly competencies: ReadonlyArray<{
          readonly id: number;
          readonly skill: {
            readonly id: number;
            readonly name: string;
          };
        }>;
        readonly email: string | null | undefined;
        readonly first_name: string;
        readonly id: number;
        readonly image_key: string | null | undefined;
        readonly last_name: string;
        readonly tags: any;
      }>;
      readonly people_tags: ReadonlyArray<{
        readonly archived: boolean;
        readonly id: number;
        readonly model: string;
        readonly name: string;
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PermissionsProvider_user">;
  } | null | undefined;
};
export type ConvertPersonTagToSkillQuery = {
  response: ConvertPersonTagToSkillQuery$data;
  variables: ConvertPersonTagToSkillQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": "allSkills",
  "args": null,
  "concreteType": "skills",
  "kind": "LinkedField",
  "name": "skills",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": "people_tags",
  "args": [
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "model": {
          "_eq": "person"
        }
      }
    }
  ],
  "concreteType": "tags",
  "kind": "LinkedField",
  "name": "tags",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "model",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archived",
      "storageKey": null
    }
  ],
  "storageKey": "tags(where:{\"model\":{\"_eq\":\"person\"}})"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "people",
  "kind": "LinkedField",
  "name": "people",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image_key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "competencies",
      "kind": "LinkedField",
      "name": "competencies",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "skills",
          "kind": "LinkedField",
          "name": "skill",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConvertPersonTagToSkillQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PermissionsProvider_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ConvertPersonTagToSkillQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timesheets_protected",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "permissions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "project_managers",
            "kind": "LinkedField",
            "name": "manageable_projects",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "projects",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "people_managers",
            "kind": "LinkedField",
            "name": "manageable_people",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "people",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "linkedPerson",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7aeb04dad7b132e03dd817f2f3323858",
    "id": null,
    "metadata": {},
    "name": "ConvertPersonTagToSkillQuery",
    "operationKind": "query",
    "text": "query ConvertPersonTagToSkillQuery {\n  current_user {\n    account {\n      id\n      allSkills: skills {\n        id\n        name\n      }\n      people_tags: tags(where: {model: {_eq: \"person\"}}) {\n        id\n        name\n        model\n        archived\n      }\n      people {\n        id\n        first_name\n        last_name\n        email\n        image_key\n        tags\n        competencies {\n          id\n          skill {\n            id\n            name\n          }\n        }\n      }\n    }\n    ...PermissionsProvider_user\n  }\n}\n\nfragment PermissionsProvider_user on users {\n  id\n  email\n  account {\n    id\n    timesheets_protected\n  }\n  permissions\n  manageable_projects {\n    id\n    project {\n      id\n    }\n  }\n  manageable_people {\n    id\n    person {\n      id\n    }\n  }\n  linkedPerson {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6b20dbda968101d9c6b4fea5b3b53242";

export default node;
