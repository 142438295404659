import { Dialog } from "@blueprintjs/core"
import { useState } from "react"
import React from "react"

import Button from "~/common/buttons/Button"

import { usePermissions } from "~/Permissions/usePermissions"
import UpgradePlanModal from "~/forms/CreatePerson/UpgradePlanModal"

type Props = {
  outlined?: boolean
  text?: string
}

const StartTrialButton = ({
  outlined = false,
  text = "Start 14 day trial",
}: Props) => {
  const { can, subject } = usePermissions()
  const isAdmin = can("manage", subject("Account"))
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button
        outlined={outlined}
        disabled={!isAdmin}
        text={text}
        onClick={() => setIsOpen(true)}
      />
      <Dialog isOpen={isOpen}>
        <UpgradePlanModal
          isAdmin={isAdmin}
          closeDialog={() => setIsOpen(false)}
          intent="StartTrial"
        />
      </Dialog>
    </>
  )
}

export default StartTrialButton
