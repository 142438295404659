import { Icon } from "@blueprintjs/core"
import cc from "classcat"
import React, { useCallback, useState } from "react"

import styles from "./InviteUserForm.module.css"

import { reportError } from "~/helpers/error-helpers"
import { ManagePeopleType, ManageProjectsType } from "~/helpers/permissions"
import { pluralize } from "~/helpers/plural"
import { userListUrl } from "~/helpers/routes"

import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import TitledHelpTooltip from "~/common/TitledHelpTooltip"
import AddButton from "~/common/buttons/AddButton"
import Button from "~/common/buttons/Button"
import InputEmail from "~/common/inputs/InputEmail"
import { Delete } from "~/common/react-icons"
import { TangramInvite } from "~/common/tangrams/tangramInvite"

import { invitationCreateRelay } from "~/mutations/Invitation"

import {
  FinancialPermissionsOption,
  MANAGE_PERMISSIONS,
  ManagePeoplePermissionsOption,
  ManageProjectsPermissionsOption,
  ManagerPermissionsOption,
  UserTypeOption,
} from "~/GLOBALVARS"
import { showToast } from "~/containers/ToasterContainer"

import SelectFinancialPermission from "./SelectFinancialPermission"
import SelectManageAccountPermission from "./SelectManageAccountPermission"
import SelectManagerType from "./SelectManagerType"
import SelectUserType from "./SelectUserType"
import SelectViewPlannerPermission from "./SelectViewPlannerPermission"

type Props = {
  closeDialog: () => void
  saveText?: string
  inSettingsPage?: boolean
  email?: string
}

type Invite = {
  email: string
  user_type: UserTypeOption
  fin_permission: FinancialPermissionsOption
  manage_projects_permission: ManageProjectsPermissionsOption
  manage_people_permission: ManagePeoplePermissionsOption
  add_all_people_to_projects_permission: boolean
  manage_account_permission: boolean
  view_planner_permission: boolean
  emailValidationError?: null | string
  error?: null | string
}

type RequestInvite = Omit<Invite, "error" | "emailValidationError">

const emptyInvite: Invite = {
  email: "",
  user_type: "manager",
  fin_permission: "none",
  manage_projects_permission: ManageProjectsType.All,
  manage_people_permission: ManagePeopleType.All,
  add_all_people_to_projects_permission: true,
  manage_account_permission: false,
  view_planner_permission: true,
  emailValidationError: null,
  error: null,
}

const ERROR_INVALID_EMAIL = "Please enter a valid email address."

const SendInviteTangram = (props: { activate: boolean }) => {
  return (
    <div
      style={{
        transition: "all 2s",
        marginBottom: 15,
        transform: `translateY(${props.activate ? "-100vh" : "0%"})`,
      }}
    >
      <TangramInvite />
    </div>
  )
}

const InviteUserForm = (props: Props) => {
  const {
    closeDialog,
    email,
    saveText = "Invite",
    inSettingsPage = false,
  } = props

  const initialInvites = [{ ...emptyInvite, email: email || "" }]

  const [activateAnimation, setActivateAnimation] = useState(false)
  const [invites, setInvites] = useState<Array<Invite>>(initialInvites)
  const [isCreating, setIsCreating] = useState(false)

  const shouldReloadPage =
    document.location.toString() === userListUrl() || inSettingsPage

  const handleInputChange = useCallback(
    <Key extends keyof Invite>(value: Invite[Key], field: Key, idx: number) => {
      const updatedInvites = [...invites]

      updatedInvites[idx][field] = value
      if (field === "email") {
        updatedInvites[idx].error = null
      }
      // Update default manager type when changing user type
      if (field === "user_type") {
        const managerPermission = ["admin", "manager"].includes(
          updatedInvites[idx].user_type,
        )
          ? "all"
          : "none"

        updatedInvites[idx].manage_projects_permission = managerPermission
        updatedInvites[idx].manage_people_permission = managerPermission

        const manageAccountPermission =
          updatedInvites[idx].user_type === "admin" ? true : false
        updatedInvites[idx].manage_account_permission = manageAccountPermission

        // Always reset view planner permission to true when changing roles
        updatedInvites[idx].view_planner_permission = true

        // Update financial to all when changing user type to admin
        if (updatedInvites[idx].user_type === "admin") {
          updatedInvites[idx].fin_permission = "all"
        }

        // Update financial permission to none when changing user type to contributor
        if (updatedInvites[idx].user_type === "contributor") {
          updatedInvites[idx].fin_permission = "none"
          updatedInvites[idx].add_all_people_to_projects_permission = false
        }
      }
      if (field === "manage_account_permission") {
        if (value) {
          updatedInvites[idx].fin_permission = "all"
        }
      }
      setInvites(updatedInvites)
    },
    [invites],
  )

  const handleManagerPermissionsChange = (
    value: ManagerPermissionsOption,
    idx: number,
  ) => {
    const managePermission = MANAGE_PERMISSIONS.find((p) => p.value === value)

    handleInputChange(
      managePermission.manage_projects,
      "manage_projects_permission",
      idx,
    )

    handleInputChange(
      managePermission.manage_people,
      "manage_people_permission",
      idx,
    )

    handleInputChange(
      managePermission.add_all_people_to_projects,
      "add_all_people_to_projects_permission",
      idx,
    )
  }

  const addItemToList = useCallback(() => {
    setInvites([...invites, { ...emptyInvite }])
  }, [invites])

  const removeItemFromList = useCallback(
    (idx) => {
      setInvites(invites.filter((i, inviteIndex) => inviteIndex !== idx))
    },
    [invites],
  )

  const handleEmailValidation = useCallback(
    (val: boolean, idx) => {
      const error = val ? ERROR_INVALID_EMAIL : null
      if (invites[idx].emailValidationError !== error) {
        const newInvites = [...invites]
        newInvites[idx].emailValidationError = error
        setInvites(newInvites)
      }
    },
    [invites],
  )

  const InvitesInputs = (idx: number) => (
    <div
      key={`invites-${idx}`}
      className={cc([styles.tableRow, styles.permissions])}
    >
      <InputEmail
        name={`invite-email-${idx}`}
        data-idx={idx}
        id={`invite-email-${idx}`}
        value={invites[idx].email}
        onChange={(e) => handleInputChange(e.target.value, "email", idx)}
        handleValidation={(val) => handleEmailValidation(val, idx)}
        className="fs-exclude"
        autoFocus
      />
      <SelectUserType
        id={`invite-user-type-${idx}`}
        name={`invite-user-type-${idx}`}
        onChange={(opt) => handleInputChange(opt.value, "user_type", idx)}
      />
      <SelectManagerType
        id={`invite-manager-projects-permission-${idx}`}
        name={`invite-manager-projects-permission-${idx}`}
        defaultValue={MANAGE_PERMISSIONS.find(
          (p) =>
            p.manage_projects === invites[idx].manage_projects_permission &&
            p.manage_people === invites[idx].manage_people_permission &&
            p.add_all_people_to_projects ===
              invites[idx].add_all_people_to_projects_permission,
        )}
        isDisabled={invites[idx].user_type !== "manager"}
        onChange={(opt) => handleManagerPermissionsChange(opt.value, idx)}
      />
      <SelectManageAccountPermission
        id={`invite-manage-account-permission-${idx}`}
        name={`invite-manage-account-permission-${idx}`}
        isDisabled={invites[idx].user_type !== "admin"}
        value={invites[idx].manage_account_permission}
        onChange={(opt) =>
          handleInputChange(opt.value, "manage_account_permission", idx)
        }
      />
      <SelectFinancialPermission
        id={`invite-financial-permission-${idx}`}
        name={`invite-financial-permission-${idx}`}
        isDisabled={
          invites[idx].user_type === "contributor" ||
          invites[idx].manage_account_permission
        }
        value={invites[idx].fin_permission}
        onChange={(opt) => handleInputChange(opt.value, "fin_permission", idx)}
      />
      <SelectViewPlannerPermission
        id={`invite-view-planner-permission-${idx}`}
        name={`invite-view-planner-permission-${idx}`}
        isDisabled={["admin", "manager"].includes(invites[idx].user_type)}
        value={invites[idx].view_planner_permission}
        onChange={(opt) =>
          handleInputChange(opt.value, "view_planner_permission", idx)
        }
      />
      <div
        onClick={invites.length > 1 ? () => removeItemFromList(idx) : null}
        className={`${styles.delete} ${
          invites.length === 1 && styles.disabled
        }`}
        tabIndex={0}
      >
        <Delete color="var(--winter)" />
      </div>
      {invites[idx].error && (
        <small className={styles.error}>{invites[idx].error}</small>
      )}
      {invites[idx].emailValidationError && (
        <small className={styles.error}>
          {invites[idx].emailValidationError}
        </small>
      )}
    </div>
  )

  const sendInvite = async (invite: RequestInvite, i: number) => {
    try {
      const res = await invitationCreateRelay({ input: invite })
      if (!res.error) {
        window.userflow?.track("User Invited")
        return [i, null]
      }

      return [i, res.error || "There has been an error."]
    } catch (error) {
      void reportError(`sendInvite error: `, error)
    }
  }

  const handleOnCreate = useCallback(async () => {
    setActivateAnimation(true)
    setIsCreating(true)

    const requestInvites = invites
      .filter((invite) => invite.email.trim())
      .map<RequestInvite>((i) => {
        return {
          email: i.email.toLowerCase(),
          user_type: i.user_type,
          fin_permission: i.fin_permission,
          manage_projects_permission: i.manage_projects_permission,
          manage_people_permission: i.manage_people_permission,
          add_all_people_to_projects_permission:
            i.add_all_people_to_projects_permission,
          manage_account_permission: i.manage_account_permission,
          view_planner_permission: i.view_planner_permission,
        }
      })

    const results = await Promise.all(
      requestInvites.map((invite, i) => sendInvite(invite, i)),
    )
    setIsCreating(false)
    const hasErrors = results.some(([idx, err]) => err)
    const successfulEmails = results
      .filter(([idx, err]) => !err)
      .map(([idx]) => invites[idx].email).length

    if (Boolean(successfulEmails)) {
      showToast({
        message: `${successfulEmails} ${pluralize(
          successfulEmails,
          "user invitation",
        )} sent`,
        type: "success",
        actions: [{ href: userListUrl(), text: "View All" }],
      })
    }

    if (hasErrors) {
      const newInvites = []
      results.forEach(([idx, error]) => {
        if (error) {
          newInvites.push({
            ...invites[idx],
            error,
          })
        }
      })
      setInvites(newInvites)
    } else {
      closeDialog()
      if (shouldReloadPage) {
        window.location.reload()
      }
    }
  }, [invites, closeDialog, shouldReloadPage])

  const hasNoCompletedRows = !invites.filter((invite) => invite.email.trim())
    .length
  const hasInvalidEmails = invites.some(
    (invite) => invite.error || invite.emailValidationError,
  )

  return (
    <ModalFormWrapper
      headerTitle="Invite Users"
      wider
      tangram={<SendInviteTangram activate={activateAnimation} />}
    >
      <ModalBody wide>
        <div className={styles.wrapper}>
          <div
            className={cc([
              styles.tableRow,
              styles.tableHeaderRow,
              styles.permissions,
            ])}
          >
            <div>
              <span>Email</span>
            </div>
            <div>
              <span>User Type</span>
            </div>
            <div>
              <TitledHelpTooltip
                title="Manager Type"
                tooltipContent={
                  <div>
                    To customize Restricted access, you'll need edit the
                    specific{" "}
                    <a
                      href="https://help.runn.io/en/articles/9755700-selecting-restricted-projects-for-a-manager"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Projects
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://help.runn.io/en/articles/9755705-selecting-restricted-people-for-a-manager"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      People
                    </a>{" "}
                    individually.
                  </div>
                }
              />
            </div>
            <div>
              <TitledHelpTooltip
                title="Edit Account Settings and Data"
                tooltipContent={
                  <div>
                    Learn more about{" "}
                    <a
                      href="https://help.runn.io/en/articles/9754839-edit-account-settings-and-data"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Restricted Data Access
                    </a>
                  </div>
                }
              />
            </div>
            <div>
              <span>Financial Data</span>
            </div>
            <div>
              <span>View Planner</span>
            </div>
            <div>{/* leave empty for delete */}</div>
          </div>
          {invites.map((_, idx) => InvitesInputs(idx))}
        </div>
        <AddButton
          id="add-invite"
          text="Add Another Invite"
          onClick={() => addItemToList()}
        />
      </ModalBody>
      <ModalFooter style={{ justifyContent: "space-between" }}>
        <a
          href="https://help.runn.io/en/articles/9743063-Managing-Permissions-User-types"
          target="_blank"
          rel="noopener"
          className={styles.link}
        >
          User Types and Permissions
          <Icon
            title="Open in a new tab"
            icon="share"
            size={13}
            className="mtb-center pl2"
          />
        </a>
        <div style={{ display: "flex" }}>
          <Button
            id="cancel-invite"
            text="Cancel"
            onClick={() => closeDialog()}
          />
          <Button
            id="create-invite"
            text={saveText}
            loading={isCreating}
            outlined={false}
            onClick={handleOnCreate}
            disabled={isCreating || hasNoCompletedRows || hasInvalidEmails}
            style={{ minWidth: 100 }}
            className={`user-invite ${isCreating ? "pulse" : ""}`}
          />
        </div>
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export default InviteUserForm
