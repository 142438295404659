import { Tooltip, TooltipProps } from "@blueprintjs/core"
import React from "react"

type Props = Omit<TooltipProps, "content"> & {
  action: string
  disabled: boolean
  subject: string
  children: JSX.Element
}

const PermissionsTooltip = (props: Props) => {
  const {
    placement = "top",
    disabled,
    action,
    subject,
    children,
    ...restProps
  } = props
  const content = `You do not have permission to ${action} ${subject}`

  return (
    <Tooltip
      {...restProps}
      disabled={disabled}
      placement={placement}
      content={content}
      openOnTargetFocus={false}
    >
      {children}
    </Tooltip>
  )
}

export default PermissionsTooltip
