import React, { useEffect, useState } from "react"

import Select, { ReactSelectProps } from "~/common/Select"

import {
  MANAGE_PERMISSIONS,
  ManageProjectsPermissionsOption,
} from "~/GLOBALVARS"

type Props = ReactSelectProps & {
  onChange: (value: ManageProjectsPermissionsOption) => void
}

const SelectManagerType = (props: Props) => {
  const { onChange, defaultValue, label, name, id, ...rest } = props

  const [selectedProjectPermission, setSelectedProjectPermission] = useState(
    defaultValue ?? MANAGE_PERMISSIONS[0],
  )

  useEffect(() => {
    setSelectedProjectPermission(defaultValue ?? MANAGE_PERMISSIONS[0])
  }, [defaultValue])

  const handleChange = (e: ManageProjectsPermissionsOption) => {
    onChange(e)
    setSelectedProjectPermission(e)
  }

  return (
    <Select
      name={name || "invite-manager-permissions"}
      id={id || "invite-manager-permissions"}
      value={selectedProjectPermission}
      onChange={handleChange}
      label={label || ""}
      options={MANAGE_PERMISSIONS}
      placeholder="Select Projects Permission"
      doNotSortOptions
      {...rest}
    />
  )
}

export default SelectManagerType
