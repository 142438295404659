/**
 * @generated SignedSource<<b7b6bc41b8cacd931be97c2193dba96d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserActionsQuery$variables = Record<PropertyKey, never>;
export type UserActionsQuery$data = {
  readonly current_user: {
    readonly account: {
      readonly account_type: string;
      readonly id: number;
      readonly name: string;
      readonly subscription: any | null | undefined;
    };
    readonly email: string;
    readonly first_name: string | null | undefined;
    readonly id: number;
    readonly last_name: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"PermissionsProvider_user">;
  } | null | undefined;
  readonly user_accounts: ReadonlyArray<{
    readonly account: {
      readonly account_type: string;
      readonly alternative_account_id: number | null | undefined;
      readonly id: number;
    };
    readonly id: number;
  }>;
  readonly users: ReadonlyArray<{
    readonly email: string;
    readonly permissions: any;
  }>;
};
export type UserActionsQuery = {
  response: UserActionsQuery$data;
  variables: UserActionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "users",
  "kind": "LinkedField",
  "name": "users",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "first_name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "account_type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscription",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "user_accounts",
  "kind": "LinkedField",
  "name": "user_accounts",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "alternative_account_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserActionsQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v3/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PermissionsProvider_user"
          }
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserActionsQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v3/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timesheets_protected",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_managers",
            "kind": "LinkedField",
            "name": "manageable_projects",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "projects",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "people_managers",
            "kind": "LinkedField",
            "name": "manageable_people",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "people",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "linkedPerson",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ]
  },
  "params": {
    "cacheID": "025d743378890fa73001948c57fbc55f",
    "id": null,
    "metadata": {},
    "name": "UserActionsQuery",
    "operationKind": "query",
    "text": "query UserActionsQuery {\n  users {\n    email\n    permissions\n  }\n  current_user {\n    id\n    first_name\n    last_name\n    email\n    account {\n      name\n      id\n      account_type\n      subscription\n    }\n    ...PermissionsProvider_user\n  }\n  user_accounts {\n    id\n    account {\n      id\n      account_type\n      alternative_account_id\n    }\n  }\n}\n\nfragment PermissionsProvider_user on users {\n  id\n  email\n  account {\n    id\n    timesheets_protected\n  }\n  permissions\n  manageable_projects {\n    id\n    project {\n      id\n    }\n  }\n  manageable_people {\n    id\n    person {\n      id\n    }\n  }\n  linkedPerson {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d549587d80e9a324451ce4f5c01f14ce";

export default node;
